import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth/auth.guard';
import { ProjectsComponent } from './projects/projects.component';

const routes: Routes = [
  {
    path: 'login',
		loadChildren: () => import('./shared/auth/auth.module').then(m => m.AuthModule)
	},
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'lead',
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
    data: { type: 'lead' },
    canActivate: [ AuthGuard ]
  },
  {
    path: 'request',
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
    data: { type: 'request' },
    canActivate: [ AuthGuard ]
  },
  {
    path: 'quote',
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
    data: { type: 'quote' },
    canActivate: [ AuthGuard ]
  },
  {
    path: 'project',
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
    data: { type: 'project' },
    canActivate: [ AuthGuard ]
  },
  {
    path: 'customers',
    loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule),
    data: { type: 'customer' },
    canActivate: [ AuthGuard ]
  },
  {
    path: 'principals',
    loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule),
    data: { type: 'principal' },
    canActivate: [ AuthGuard ]
  },
  // {
  //   path: 'customer',
  //   loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule),
  //   data: { type: 'customer' },
  //   canActivate: [ AuthGuard ]
  // },
  {
    path: 'principal/:slug',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    // data: { type: 'principal' },
    canActivate: [ AuthGuard ]
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'documents',
    loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [ AuthGuard ]
  },
  {
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules } )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
