import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, map } from 'rxjs';
import { Router, Scroll } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { AuthService } from './services/auth.service';
import { delay, filter } from 'rxjs/operators';
import { User } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loggedIn: boolean = false;
  currentUser!: User;
  loggingOut: boolean = false;

  constructor(
    private apollo: Apollo,
    private location: Location,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    // Check if user is logged in
    this.authService.isAuthenticated.subscribe( {
      next: (auth: any) => {
        setTimeout(() => {
          this.loggedIn = auth;
        }, 400);

        if(auth) {
          // Get current user data
          if( localStorage.getItem( 'currentUser') ) {
            this.currentUser = JSON.parse( localStorage.getItem( 'currentUser' ) as string );
          } else {
            this.authService.getCurrentUser().subscribe({
              next: (res: any) => {
                this.currentUser = res.authUser
                localStorage.setItem( 'currentUser', JSON.stringify(res.authUser) );
              },
              error: (error: any) => {
                console.error(error)
              }
            } );
          }
        }
      },
      error: (error: any) => {
        console.error(error)
      }
    });

    // Scroll main to top on route change
    let main = document.getElementById('main') as HTMLElement;
    this.router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .pipe(delay(1))
      .subscribe((e) => {
        if(main) {
          main.scrollTop = 0;
        }
      });
  }

  // Get initials from names for avatar
  getUserInitials( firstName: string, lastName: string ) {
    return (firstName ? Array.from(firstName)[0] : '') + (lastName ? Array.from(lastName)[0] : '');
  }

  // Logout
  logout() {
    this.loggingOut = true;
    this.authService.logout().subscribe({
      next: (res: any) => {
        // console.log( res );
        this.loggingOut = false;
        localStorage.clear();
        this.authService.isAuthenticated.next( false );
        // this.apollo.client.resetStore();
        this.location.replaceState('/login');
        this.router.navigate(['/login']);
        return false;
      },
      error: (err: any) => {
        console.error( err );
        this.loggingOut = false;
      }
    });
  }
}
