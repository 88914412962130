import { NgModule, Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { onError } from "@apollo/client/link/error";
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, ApolloClientOptions, InMemoryCache, from } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import PusherLink from 'apollo-pusher-link';
import Pusher from 'pusher-js';


export function createApollo( httpLink: HttpLink ): ApolloClientOptions<any> {
  const basic = setContext( ( operation: any, context: any ) => ( {
    headers: {
      Accept: 'charset=utf-8',
    },
  } ) );

  const http = httpLink.create({
    uri: environment.GRAPHQL_API,
    headers: new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem( 'token' )}`)
  });

  const pusher = new PusherLink({
    pusher: new Pusher(environment.PUSHER_API_KEY, {
      cluster: environment.PUSHER_CLUSTER,
      authEndpoint: environment.PUSHER_AUTH_EP,
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem( 'token' )}`,
        },
      },
    })
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        if(message === 'Unauthenticated.') {
          localStorage.clear();
          window.location.href = '/login';
        }
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const link = ApolloLink.from( [errorLink, basic, pusher, http] );
  const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
}

@NgModule( {
  exports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
} )
export class GraphQLModule {}
