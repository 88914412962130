import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Apollo, gql, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { map, BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from './user.service';
import { environment } from 'src/environments/environment';

export interface AuthError {
  message: string
}

export interface AuthAccessToken {
  token: string
  expires_at: number
}

export interface AuthLoginSuccess {
  token: AuthAccessToken
  user: User
}

export interface AuthLogoutSuccess {
  tokens: number
  user: User
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public redirectUrl: string = environment.BASE_HREF + '/';

  constructor(
    private apollo: Apollo,
    private router: Router,
    private location: Location
  ) {
		this.isAuthenticated.next( !!localStorage.getItem( 'token' ) );
  }

  // Login
  login( email: string, password: string ): Observable<any> {
    const LOGIN =  gql`
      mutation Login {
        authLogin(input: {email: "${email}", password: "${password}"}) {
          ... on AuthLoginSuccess {
            __typename
            token {
              expires_at
              token
            }
            user {
              id
              first_name
              last_name
              title
              role
              email
              mobile
              phone
              is_active
              created_at
              updated_at
              manager {
                id
              }
              organizations {
                id
              }
              favoriteProjects {
                id
              }
            }
          }
          ... on AuthError {
            __typename
            message
          }
        }
      }
    `;

    return this.apollo.mutate({
      mutation: LOGIN
    });
  }


  // Logout
  logout(): Observable<any> {
    const LOGOUT = gql`
      mutation Logout {
        authLogout {
          ... on AuthLogoutSuccess {
            __typename
            tokens
            user {
              id
            }
          }
          ... on AuthError {
            __typename
            message
          }
        }
      }
    `;

    return this.apollo.mutate({
      mutation: LOGOUT
    });
  }


  // Logout All
  logoutAll() {
    const LOGOUT = gql`
      mutation Logout {
        authLogoutAll {
          ... on AuthLogoutSuccess {
            __typename
            tokens
            user {
              id
            }
          }
          ... on AuthError {
            __typename
            message
          }
        }
      }
    `;

    this.apollo.mutate({
      mutation: LOGOUT
    }).subscribe({
      next: (res: any) => {
        localStorage.clear();
        this.isAuthenticated.next( false );
        // this.apollo.client.resetStore();
        this.location.replaceState('/login');
        this.router.navigate(['/login']);
        return false;
      },
      error: (err: any) => {
        console.error( err );
      }
    });
  }


  // Current Logged In User
  getCurrentUser() {
    const GET_USER = gql`
      query currentUser {
        authUser {
          id
          first_name
          last_name
          title
          role
          email
          mobile
          phone
          is_active
          created_at
          updated_at
          manager {
            id
            first_name
            last_name
            email
            phone
            mobile
            role
            title
            notes
            is_active
          }
          organizations {
            id
          }
          favoriteProjects {
            id
          }
        }
      }
    `;

    return this.apollo.watchQuery({
      query: GET_USER
    }).valueChanges.pipe( map( ( result: any ) => result.data ) );
  }

}
