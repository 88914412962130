import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  loggedIn: boolean = false;

  constructor(
    private authService: AuthService,
    private location: Location,
    private router: Router
  ) {
		this.authService.isAuthenticated.subscribe( (loggedIn: boolean) => this.loggedIn = loggedIn );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if( this.loggedIn ) return true;

    console.log( environment.BASE_HREF + state.url );

    this.authService.redirectUrl = environment.BASE_HREF + state.url;

    localStorage.clear();
    this.location.replaceState('/login');
    this.router.navigate(['/login']);
		return false;
  }
}
